import { Injectable } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ELAutocompleteElement } from '@el-autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { isArray, isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, takeUntil } from 'rxjs';

import { CONFIGURABLE_FIELD_DATA_TYPES } from '@shared/constants';
import {
  ICityReference,
  ICityReferenceWithDistrict,
  IConfigurableFieldConfigResponse,
  IConfigurableFieldDataTypes,
  IConfigurableFieldValueResponse,
  IContactAddress,
  IContactEmail,
  IContactNumber,
  ICountryReference,
  IDistrictReferenceWithState,
  IName,
  IReference,
  IReferenceResponse,
  IStateReferenceWithCountry,
} from '@shared/interfaces';
import { emailValidator, isNonEmpty, isRequired, isSort } from '@shared/utils';

import { SnackbarService } from '../../../services/snackbar.service';
import { ReferenceService } from '../../references/services/reference.service';
import { IDisplayConfigurableFieldElement } from '../types';

import { ConfigurableFieldValueService } from './configurable-field-value.service';
import { MultiFieldService } from './multi-field.service';

const basicTypes = [
  CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
  CONFIGURABLE_FIELD_DATA_TYPES.NUMBER,
];

export interface IFieldValidationResults {
  isValid: boolean;
  data?: IConfigurableFieldValueResponse[];
  error?: string;
}

@Injectable({ providedIn: 'root' })
export class DisplayConfigurableFieldService {
  public $isUnique = new BehaviorSubject<boolean>(false);
  constructor(
    private referenceService: ReferenceService,
    private multiFieldService: MultiFieldService,
    private configurableFieldValueService: ConfigurableFieldValueService,
    private snackBar: SnackbarService,
    private translateService: TranslateService
  ) {}

  /**
   *
   * @param fields Array of field data needed to generate the form fields
   * @param fetchReferencesFrom 'internal', 'iam', connected-app id or an array containing all references
   * @param onDestroy$
   * @param oldValue
   * @returns
   */
  async generateFormFields(
    fields: IConfigurableFieldConfigResponse[],
    fetchReferencesFrom:
      | 'internal'
      | 'iam'
      | { app: string }
      | { predefined: IReferenceResponse[] },
    onDestroy$: Observable<void>,
    oldValue?: IConfigurableFieldValueResponse[],
    predefinedRef?: IReference<
      | ICountryReference
      | IStateReferenceWithCountry
      | IDistrictReferenceWithState
      | ICityReferenceWithDistrict
    >
  ): Promise<{
    fields: IDisplayConfigurableFieldElement[];
    validation: Observable<IFieldValidationResults>;
  }> {
    const displayFields = await Promise.all(
      fields.map(async (field) => {
        const { _id: field_id, name, type, dropdown_options } = field;

        const is_required = isRequired(field?.checkboxes);
        const is_sort = isSort(field?.checkboxes);
        const errorFormula = new BehaviorSubject(false);
        const field_value = new UntypedFormControl('');

        const _field: IDisplayConfigurableFieldElement = {
          configuration: field,
          field_value,
          templateName: name,
          inputType: type,
          references: undefined,
          errorFormula,
        };

        // set as advanced configurable field if advanced config properties exist
        if (field.layout || field.criteria) {
          _field.advancedConfig = {
            width: field.layout,
            disableOnCriteria: false,
          };
        }

        switch (type) {
          case CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES: {
            if (field.reference_type_field_config) {
              if (
                typeof fetchReferencesFrom === 'object' &&
                'predefined' in fetchReferencesFrom
              ) {
                const matchingReferenceData =
                  fetchReferencesFrom.predefined.filter(
                    (reference) =>
                      reference.category_id.toString() ===
                      field?.reference_type_field_config?.category_id?.toString()
                  );

                _field.references = await this.getReferenceAutocompleteElements(
                  { predefined: matchingReferenceData },
                  field?.reference_type_field_config?.category_id?.toString(),
                  field?.reference_type_field_config?.field_id?.toString(),
                  false
                );
              } else {
                _field.references = await this.getReferenceAutocompleteElements(
                  fetchReferencesFrom,
                  field?.reference_type_field_config?.category_id?.toString(),
                  field?.reference_type_field_config?.field_id?.toString(),
                  is_sort
                );
              }
            } else {
              this.snackBar.error(
                this.translateService.instant(
                  'configurable-fields.missing-reference-configurations',
                  { field: _field.configuration.name }
                )
              );
            }

            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.DROPDOWN: {
            _field.dropdownOptions = dropdown_options ?? [];
          }
        }

        if (!isEmpty(oldValue)) {
          const refValue = oldValue.find(
            (fieldVal) => fieldVal.field_id === field_id
          );

          if (!refValue) return _field;

          let _field_value:
            | string
            | ELAutocompleteElement
            | IConfigurableFieldDataTypes = refValue.value;

          if (
            field.type === CONFIGURABLE_FIELD_DATA_TYPES.DATE ||
            field.type === CONFIGURABLE_FIELD_DATA_TYPES.TIME ||
            field.type === CONFIGURABLE_FIELD_DATA_TYPES.DATE_TIME
          ) {
            _field_value = new Date(refValue.value.toString());
          }

          field_value.setValue(_field_value);
        } else {
          field_value.setValue(field.default_value || '');
        }

        if (is_required) {
          field_value.setValidators(Validators.required);
        }

        if (field.regexField) {
          const regexObj = field.regexField;
          const regexType = regexObj.type;
          if (regexType === 'email') {
            field_value.addValidators(emailValidator());
          } else {
            field_value.addValidators(Validators.pattern(regexObj.regex));
          }
        }

        if (basicTypes.includes(type)) {
          _field.inputType = field.type;
        }

        if (oldValue && _field) {
          let num: number;
          oldValue.find((value, index) => {
            if (value.field_id === _field.configuration._id) {
              num = index;
            }
          });
          const savedValue = oldValue[num]?.value;

          if (_field?.inputType === CONFIGURABLE_FIELD_DATA_TYPES.ADDRESS) {
            if (isArray(savedValue)) {
              if (!(savedValue[0] as IContactAddress)?.address) {
                _field?.field_value?.setValue(null);
              }
            }
          }

          if (_field?.inputType === CONFIGURABLE_FIELD_DATA_TYPES.NAME) {
            if (!(savedValue as IName)?.first_name)
              _field?.field_value?.setValue(null);
          }

          if (
            _field?.inputType === CONFIGURABLE_FIELD_DATA_TYPES.CONTACT_NUMBER
          ) {
            if (isArray(savedValue)) {
              if (!(savedValue[0] as IContactNumber)?.phone_number)
                _field?.field_value?.setValue(null);
            } else {
              _field?.field_value?.setValue(null);
            }
          }

          if (_field?.inputType === CONFIGURABLE_FIELD_DATA_TYPES.EMAIL) {
            if (isArray(savedValue)) {
              if (!(savedValue[0] as IContactEmail)?.email)
                _field?.field_value?.setValue(null);
            } else {
              _field?.field_value?.setValue(null);
            }
          }

          if (_field?.inputType === CONFIGURABLE_FIELD_DATA_TYPES.DATE) {
            const isDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
            if (
              (typeof savedValue === 'string' &&
                !isDateRegex.test(savedValue)) ||
              isArray(savedValue)
            ) {
              _field.field_value.setValue(null);
            }
          }
          if (_field?.inputType === CONFIGURABLE_FIELD_DATA_TYPES.DATE_TIME) {
            const isTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
            if (
              (typeof savedValue === 'string' &&
                !isTimeRegex.test(savedValue)) ||
              isArray(savedValue)
            ) {
              _field.field_value.setValue(null);
            }
          }

          if (_field?.inputType === CONFIGURABLE_FIELD_DATA_TYPES.TIME) {
            const isTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
            if (
              (typeof savedValue === 'string' &&
                !isTimeRegex.test(savedValue)) ||
              isArray(savedValue)
            ) {
              _field.field_value.setValue(null);
            }
          }
        }

        switch (field?.name) {
          case 'name':
            field_value.setValue(predefinedRef?.name);
            break;
          case 'code':
            field_value.setValue(predefinedRef?.code);
            break;
          case 'country':
            field_value.setValue(
              (predefinedRef as IReference<IStateReferenceWithCountry>)
                ?.countryData?.code
            );
            break;
          case 'state':
            field_value.setValue(
              (predefinedRef as IReference<IDistrictReferenceWithState>)
                ?.stateData?.code
            );
            break;
          case 'district':
            field_value.setValue(
              (predefinedRef as IReference<ICityReferenceWithDistrict>)
                ?.districtData?.code
            );
            break;
          case 'postal_code':
            field_value.setValue(
              (predefinedRef as IReference<ICityReference>)?.postal_code
            );
            break;
          default:
            break;
        }

        return _field;
      })
    );

    const fieldValidation = new BehaviorSubject<IFieldValidationResults>(
      await this.validateAndGetValueOfConfigurableFields(displayFields)
    );

    displayFields.forEach((field) => {
      combineLatest([field.field_value.valueChanges, field.errorFormula])
        .pipe(takeUntil(onDestroy$))
        .subscribe(async () => {
          fieldValidation.next(
            await this.validateAndGetValueOfConfigurableFields(displayFields)
          );
        });
    });

    return {
      fields: displayFields,
      validation: fieldValidation.asObservable(),
    };
  }

  /**
   *
   * @param fetchReferencesFrom
   * @param referenceCategory 'internal', 'iam', connected-app id or an array containing the references to be used
   * @param referenceField
   * @param sortItems
   * @returns
   */
  async getReferenceAutocompleteElements(
    fetchReferencesFrom:
      | 'internal'
      | 'iam'
      | { app: string }
      | { predefined: IReferenceResponse[] },
    referenceCategory: string,
    referenceField: string,
    sortItems: boolean
  ): Promise<ELAutocompleteElement<IReferenceResponse>[]> {
    let references: ELAutocompleteElement<IReferenceResponse>[] = [];

    let children: IReferenceResponse[] = [];
    if (
      typeof fetchReferencesFrom === 'object' &&
      'predefined' in fetchReferencesFrom
    ) {
      children = fetchReferencesFrom.predefined;
    } else {
      children = await this.referenceService.getReferences(
        referenceCategory,
        'valid',
        false,
        fetchReferencesFrom
      );
    }
    references = (
      await Promise.all(
        children.map(async (child) => {
          const reference = child.reference.find(
            (_reference) => _reference.field_id.toString() === referenceField
          );
          if (!reference) return null;

          const displayValue =
            await this.configurableFieldValueService.fieldValueToString({
              valueResponse: reference,
              app_id:
                typeof fetchReferencesFrom === 'object' &&
                'app' in fetchReferencesFrom
                  ? fetchReferencesFrom.app
                  : undefined,
              categoryId: referenceCategory,
            });
          const value = child._id?.toString();

          return {
            value,
            displayValue: displayValue ?? value,
            originalData: child,
          };
        })
      )
    ).filter(Boolean);

    if (sortItems) {
      references = references.sort((a, b) => (a.value > b.value ? 1 : -1));
    }

    return references;
  }

  public listenToUniqueChange(value: boolean) {
    this.$isUnique.next(value);
  }

  async validateAndGetValueOfConfigurableFields(
    fields: IDisplayConfigurableFieldElement[]
  ): Promise<IFieldValidationResults> {
    const data: IConfigurableFieldValueResponse[] = [];

    for (const field of fields) {
      const disableOnCriteria = field.advancedConfig?.disableOnCriteria;

      if (disableOnCriteria) continue;

      const is_required = isRequired(field.configuration.checkboxes);
      let { value } = field.field_value;
      let valid = false;

      if (isNonEmpty(value)) {
        switch (field.configuration.type) {
          case CONFIGURABLE_FIELD_DATA_TYPES.TEXT: {
            valid = typeof value === 'string';
            if (field.configuration.regexField) {
              const regexObj = field.configuration.regexField;
              valid = new RegExp(regexObj.regex).test(value);
            }
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.CURRENCY:
          case CONFIGURABLE_FIELD_DATA_TYPES.NUMBER: {
            valid =
              typeof value === 'number' || !isNaN(Number(value.toString()));
            value = Number(value);
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.TIME:
          case CONFIGURABLE_FIELD_DATA_TYPES.DATE_TIME:
          case CONFIGURABLE_FIELD_DATA_TYPES.DATE: {
            valid = !isNaN(Date.parse((value || '').toString()));
            value = new Date(value.toString()).toISOString();
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.BOOLEAN: {
            valid =
              typeof value === 'boolean' ||
              value === 'true' ||
              value === 'false';
            value = value.toString();
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.FORMULA: {
            if (field.errorFormula.value) {
              return {
                isValid: false,
                error: `The formula in "${field.templateName}" is invalid`,
              };
            }

            valid = true;
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.JSON: {
            try {
              const parsed = JSON.parse(value.toString());
              valid = !isEmpty(parsed);
            } catch {
              valid = false;
            }
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.VIEW:
          case CONFIGURABLE_FIELD_DATA_TYPES.RICH_TEXT:
          case CONFIGURABLE_FIELD_DATA_TYPES.FILE: {
            // TODO:@sampath implement this correctly
            valid = true;
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES: {
            valid = true;
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.DROPDOWN: {
            valid = !!value;
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.ADDRESS: {
            valid =
              (
                await Promise.all(
                  (value as IContactAddress[]).map((address) =>
                    this.multiFieldService.isValidAddress(address)
                  )
                )
              ).filter(Boolean).length === value.length;
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.NAME: {
            valid = await this.multiFieldService.isValidName(value as IName);
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.CONTACT_NUMBER: {
            valid =
              (
                await Promise.all(
                  (value as IContactNumber[]).map((contactNumber) =>
                    this.multiFieldService.isValidPhone(contactNumber)
                  )
                )
              ).filter(Boolean).length === value.length;
            break;
          }
          case CONFIGURABLE_FIELD_DATA_TYPES.EMAIL: {
            valid =
              (
                await Promise.all(
                  (value as IContactEmail[]).map((contactEmail) =>
                    this.multiFieldService.isValidEmail(contactEmail)
                  )
                )
              ).filter(Boolean).length === value.length;
            break;
          }

          case CONFIGURABLE_FIELD_DATA_TYPES.CHECKBOX: {
            if (Array.isArray(value)) {
              valid = value.every((option) => typeof option === 'string');
            }
            break;
          }

          case CONFIGURABLE_FIELD_DATA_TYPES.RADIO_BUTTON: {
            valid = typeof value === 'string';
            break;
          }
        }
      }

      if (!is_required && !isNonEmpty(value)) {
        // if not required and no value, then go forward
        continue;
      } else if (is_required && !isNonEmpty(value)) {
        // if required and no value, then it is an issue
        return {
          isValid: false,
          error: `Value for field ${field.configuration.name} is required`,
        };
      } else if (!valid) {
        // if required or has value, and the value is not valid

        return {
          isValid: false,
          error: `Data provided to the field ${field.configuration.name} is mismatch with the field config`,
        };
      }

      data.push({
        field_id: field.configuration._id,
        field: field.configuration,
        value,
      });
    }

    return {
      isValid: true,
      data,
    };
  }
}
