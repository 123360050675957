export const URLS = {
  // AUTH ROUTES
  AUTH_LOGIN: '/api/auth/login',
  AUTH_PROFILE_IMAGE_UPLOAD: '/api/auth/upload/profile-image',
  AUTH_REFRESH_TOKEN: `/api/auth/token`,
  AUTH_LOGOUT: `/api/auth/logout`,
  AUTH_FORGOT_PASSWORD: `/api/auth/password/forgot`,
  AUTH_RESET_PASSWORD: `/api/auth/password/reset`,
  AUTH_VALIDATE_TOKEN: `/api/auth/token/validate`,
  AUTH_REGISTER: `/api/auth/register`,
  AUTH_VERIFY: `/api/auth/verify`,
  AUTH_CHECK_USER: `/api/auth/check`,
  AUTH_PROFILE: `/api/auth/profile`,
  AUTH_SEND_OTP: `/api/auth/otp/send`,
  AUTH_PUBLIC_TOKEN: `/api/public/auth/token`,

  // CONNECTED APPS
  CONNECTED_APPS: '/api/connected-apps',
  CONNECTED_APPS_GENERATE_SECRETS: '/api/connected-apps/generate',
  CONNECTED_APPS_HANDSHAKE: '/api/connected-apps/handshake',

  // THIRD PARTY APIs
  THIRD_PARTY_GEO_IP: 'https://geoip.el.web.lk/api/geoip',
  THIRD_PARTY_COUNTRIES_LIST: 'https://restcountries.com/v2/all',

  // CONNECTIONS
  CONNECTIONS: '/api/connections',
  CONNECTIONS_RESTORE_CONNECTION: '/api/connections/restore',
  CONNECTIONS_INTERNAL: '/api/internal/connections',

  // INTERNAL IDENTITIES
  INTERNAL_IDENTITIES: '/api/internal/identities',
  INTERNAL_IDENTITIES_ALL: '/api/internal/identities/all',
  INTERNAL_IDENTITIES_INTERNAL_FIELDS:
    '/api/internal/identities/internal-fields',

  // IDENTITIES
  IDENTITIES: '/api/identities',
  IDENTITIES_PUBLIC: '/api/identities/public',
  IDENTITIES_BULK: '/api/identities/bulk',
  IDENTITIES_EXTERNAL_FIELDS: '/api/identities/external-fields',
  IDENTITIES_HIERARCHY: '/api/identities/hierarchy',
  IDENTITIES_GET_INTERNAL_FIELD: '/api/identities/get-field-data',
  IDENTITIES_HIERARCHY_V2: '/api/v2/identities/hierarchy',
  IDENTITIES_HIERARCHY_SUBORDINATES: '/api/identities/subordinates',
  IDENTITIES_CSV: '/api/identities/export',
  IDENTITIES_MULTIPLE_IDENTITIES: '/api/identities/multi',
  IDENTITIES_USERNAME: '/api/identities/username',

  // ARTICLES
  ARTICLES: '/api/articles',
  ARTICLES_CATEGORIES: '/api/articles/categories',
  ARTICLES_SUB_CATEGORIES: '/api/articles/subcategories',
  ARTICLES_TAGS: '/api/articles/tags',
  ARTICLES_DRAFT_ARTICLES: '/api/articles/draft',

  // AUTH
  AUTH: 'api/auth',

  // LOGS
  LOGS: '/api/logs',

  // MESSAGES
  MESSAGES_BASE_URL: '/api/v2/messages',

  // NOTICE
  NOTICE: '/api/notices',
  NOTICE_DRAFT_NOTICE: '/api/notices/draft',

  // PERMISSIONS
  PERMISSIONS: '/api/permissions',
  PRIVILEGES: '/api/privileges',

  // ROLES
  ROLES: '/api/roles',

  // STORAGE PREVIEW
  // STORAGE: '/api/storages',
  STORAGE_PREVIEW_SHARED_STORAGES: '/api/shared/entity',

  // COLOR_PALETTE
  COLOR_PALETTE_BASE_URL: '/api/setup/color-palettes',

  // DEBUGGING
  DEBUGGING_BASE_URL: '/api/setup/debugging',

  // CLIENT DATA
  CLIENT_DATA_CLIENT: '/api/setup/client',

  // SYSTEM DATA
  SYSTEM_DATA_SYSTEM: '/api/setup/general',
  SYSTEM_DATA_ERROR_LOGS_EMAIL: '/api/setup/general/email',

  // IAM_CONFIG
  IAM_CONFIG_IAM: '/api/setup/iam-config',
  IAM_CONFIG_IAM_AUTH_TYPE: '/api/setup/iam-config/auth-type',
  IAM_CONFIG_IAM_DELETE: '/api/setup/iam-config/delete',
  IAM_CONFIG_SECURITY: '/api/setup/iam-config/security',
  IAM_CONFIG_USER_MANAGEMENT: '/api/setup/iam-config/user-management',
  IAM_CONFIG_PASSWORD_CONFIG: '/api/setup/iam-config/password',

  // MAINTENANCE
  MAINTENANCE_RESET: '/api/reset',
  MAINTENANCE_RESTART: '/api/restart',

  //SETUP MESSAGE CONFIG
  MESSAGE_SETUP_BASE_URL: '/api/setup/message',
  MESSAGE_GROUPS_BASE_URL: '/api/setup/message-groups',
  MESSAGE_RULES_BASE_URL: '/api/v2/setup/message-rules',
  MESSAGE_CONFIG_AUTO_CLOSE: '/api/setup/message/auto-close',
  MESSAGE_REPORTS_TO: '/api/setup/message/reports-to',
  MESSAGE_PARENT_ROLE: '/api/setup/message/parent-role',
  MESSAGE_IDENTITY_SUBORDINATE: '/api/setup/message/identity-subordinate',
  MESSAGE_ROLE_SUBORDINATE: '/api/setup/message/role-subordinate',

  // NOTIFICATION CONFIG
  NOTIFICATION_CONFIG_NOTIFICATION: '/api/setup/notification-config',
  NOTIFICATION_CONFIG_GET_EMAIL_PASSWORD:
    '/api/setup/notification-config/email-password',
  NOTIFICATION_CONFIG_GET_SMS_PASSWORD:
    '/api/setup/notification-config/sms-password',
  NOTIFICATION_CONFIG_GET_PUSH_NOTIFICATION_KEY:
    '/api/setup/notification-config/push-notifications/key',
  NOTIFICATION_CONFIG_TEST_EMAIL: '/api/setup/notification-config/test-email',
  NOTIFICATION_CONFIG_TEST_SMS: '/api/setup/notification-config/test-sms',

  // NOTIFICATION
  NOTIFICATIONS: '/api/notifications',

  // PWA
  PWA: '/api/setup/pwa',

  // RELEASE
  RELEASES: '/api/setup/releases',
  RELEASE_LATEST_RELEASE: '/api/setup/releases/latest',

  // SECURITY
  SECURITY_BASE_URL: '/api/setup/security',

  // STORAGE SETUP
  STORAGE_SETUP_STORAGE: '/api/setup/storage',
  STORAGE_SETUP_RECYCLE_BIN: '/api/setup/storage/recycle-bin',
  STORAGE_SETUP_SYNCFUSION_SERVICE_URL: '/api/setup/storage/syncfusion-service',
  STORAGE_SETUP_STORAGE_QUOTA: '/api/setup/storage/quota',
  STORAGE_SETUP_STORAGE_SIZE: '/api/setup/storage/size',

  // SUPPORT
  SUPPORT: '/api/setup/support',
  SUPPORT_SUPPORT_ENABLE: '/api/setup/support/enable-status',

  // SYSTEM VARIABLES
  SYSTEM_VARIABLES: '/api/setup/system-variables',
  SYSTEM_VARIABLES_BY_KEY: '/api/setup/system-variables/key',

  // STORAGE
  STORAGE: '/api/storages',
  STORAGE_ENTITY_EXTERNAL_STORAGE: '/api/external/storage',
  STORAGE_ENTITY_RECYCLE_BIN: '/api/bin',

  // INTERNAL STORAGE
  INTERNAL_STORAGE: '/api/internal/storage',

  // DIARY
  DIARY_REMINDER: '/api/diary/reminder',
  DIARY_TASK: '/api/diary/task',

  // NOTICES
  NOTICES: '/api/notices',

  // POST IT NOTES
  POST_IT_NOTES_NOTES: '/api/post-it-notes',
  POST_IT_NOTES_ARCHIVED: '/api/post-it-notes/archived',
  POST_IT_NOTES_REARRANGE: '/api/post-it-notes/rearrange',
  POST_IT_NOTES_RESTORE: '/api/post-it-notes/restore',
  POST_IT_NOTES_DELETE: '/api/post-it-notes/delete',

  // QUICK ACTION
  QUICK_ACTIONS: '/api/quick-actions',
  QUICK_ACTION_ICON_LIST: '/api/quick-actions/icon-list',
  QUICK_ACTION_REORDER_ACTION: '/api/quick-actions/reorder-action',

  // WIDGETS
  WIDGETS: '/api/widgets',
  WIDGET_LAYOUT: '/api/widget-layouts',
};
