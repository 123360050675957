import { ENVIRONMENTS, IWebEnvironment } from '@shared/interfaces';

/* eslint-disable @typescript-eslint/no-var-requires */
export const environment: IWebEnvironment = {
  env: ENVIRONMENTS.DEV,
  production: false,
  isDebugMode: true,
  isSentryDebugMode: false,
  VERSION: require('../../../../package.json').version,
  CORE_VERSION: require('../../../../package.json').coreversion,
  API_URL: window.location.origin || 'https://dev.iam.jic.echonlabs.com', // NOTE: New Change is only for Base Neo project
  WEB_URL: window.location.origin || 'https://dev.iam.jic.echonlabs.com', // NOTE: New Change is only for Base Neo project
  WEB_DOMAIN: window.location.hostname || 'dev.iam.jic.echonlabs.com', // NOTE: New Change is only for Base Neo project
  API_DOMAIN: window.location.hostname || 'dev.iam.jic.echonlabs.com',
  SENTRY_DSN: process.env.NX_API_SENTRY_DSN,
};
