import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';
import {
  BehaviorSubject,
  firstValueFrom,
  from,
  map,
  mergeMap,
  Observable,
  switchMap,
  zip,
} from 'rxjs';

import { ENDPOINTS, EXTERNAL_SYSTEMS } from '@shared/constants';
import {
  CommonResponseDTO,
  IGetEntityQuery,
  IIdentity,
  IIdentityResponse,
  IStorageEntity,
  IStorageEntityResponse,
  IUploadFileToDMS,
} from '@shared/interfaces';
import { blobToBase64, generateURL } from '@shared/utils';

import { AuthService, LoggedUserService } from '../modules/auth/services';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private token = new BehaviorSubject<string>(null);
  public loggedUserProfilePicture = new BehaviorSubject<string>(null);
  loggedUserId: string;

  constructor(
    private http: HttpClient,
    authService: AuthService,
    loggedUserService: LoggedUserService
  ) {
    authService.token.asObservable().subscribe((change) => {
      this.token.next(change);
    });

    zip(authService.token.asObservable(), loggedUserService.dataStore)
      .pipe(
        mergeMap(async ([, loggedUser]) => {
          this.loggedUserId = loggedUser?._id?.toString();

          if (loggedUser?.profile_image || loggedUser?.profile_image_url) {
            const profilePicture = loggedUser?.profile_image_url
              ? await this.getProfileImageFromUrl(loggedUser.profile_image_url)
              : await this.getDMSEntityFromId(loggedUser.profile_image);

            if (profilePicture) {
              return blobToBase64(profilePicture);
            }
          }
          return this.getGravatar(loggedUser?._id.toString());
        })
      )
      .subscribe((change) => {
        this.loggedUserProfilePicture.next(change);
        localStorage.setItem('profile_image', change);
      });

    authService.token.asObservable().subscribe((change) => {
      this.token.next(change);
    });
  }

  uploadFileToLocalServer(
    file: File
  ): Observable<CommonResponseDTO<{ path: string }>> {
    const url = generateURL({ endpoint: ENDPOINTS.ASSETS_IMAGES_UPLOAD });
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<CommonResponseDTO<{ path: string }>>(url, formData);
  }

  deleteEntityFromLocalServer(
    name: string
  ): Observable<CommonResponseDTO<{ name: string }>> {
    const url = generateURL({
      endpoint: ENDPOINTS.ASSETS_IMAGES_DELETE,
      params: { name },
    });

    return this.http.delete<CommonResponseDTO<{ name: string }>>(url);
  }

  deleteEntityFromDMS(
    entityId: string
  ): Observable<CommonResponseDTO<IStorageEntity>> {
    const url = generateURL({
      endpoint: ENDPOINTS.STORAGE_INTERNAL_DELETE,
      params: { entityId },
    });
    return this.http.delete<CommonResponseDTO<IStorageEntity>>(url);
  }

  getProfilePictureListener(identity: IIdentity): Observable<string> {
    return this.token.asObservable().pipe(
      mergeMap(async () => {
        if (identity?.profile_image || identity?.profile_image_url) {
          const profilePicture = identity?.profile_image_url
            ? await this.getProfileImageFromUrl(
                identity.profile_image_url,
                true
              )
            : await this.getDMSEntityFromId(identity.profile_image, true);

          if (profilePicture) {
            return blobToBase64(profilePicture);
          }
        }
        return this.getGravatar(identity?._id.toString());
      })
    );
  }

  async getProfilePictureOneTime(
    identity?: IIdentity | IIdentityResponse,
    is_thumbnail?: boolean
  ): Promise<string> {
    if (identity?.profile_image || identity?.profile_image_url) {
      const profilePicture = identity?.profile_image_url
        ? await this.getProfileImageFromUrl(
            identity.profile_image_url,
            is_thumbnail
          )
        : await this.getDMSEntityFromId(identity.profile_image, true);

      if (profilePicture) {
        const imageString = blobToBase64(profilePicture);

        if (this.loggedUserId === identity._id.toString()) {
          this.loggedUserProfilePicture.next(await imageString);
          localStorage.setItem('profile_image', await imageString);
        }
        return imageString;
      }
    }
    return this.getGravatar(identity?._id?.toString());
  }

  async getProfilePictureOneTimeForHierarchy(identity?: {
    identity_id: string;
    image: string;
  }): Promise<string> {
    if (identity?.image) {
      const profilePicture = await this.getDMSEntityFromId(
        identity.image,
        true
      );

      if (profilePicture) {
        return blobToBase64(profilePicture);
      }
    }
    return this.getGravatar(identity?.identity_id?.toString());
  }

  getGravatar(key: string): string {
    const hash = Md5.hashStr(key?.toString()?.toLocaleLowerCase().trim() || '');

    return `https://www.gravatar.com/avatar/${hash}?d=retro`;
  }

  getDMSFilePreviewUrlListener(
    entityId: string,
    externalSystem?: EXTERNAL_SYSTEMS
  ): Observable<string> {
    const url = generateURL({
      endpoint: ENDPOINTS.STORAGE_INTERNAL_PREVIEW,
      params: { entityId },
      version: 2,
    });

    return this.http
      .get<CommonResponseDTO<string>>(url, {
        params: {
          ...(externalSystem ? { external_system: externalSystem } : {}),
        },
      })
      .pipe(map((res) => res.data));
  }

  getProfileImageFromUrl(
    profile_image_url: string,
    isThumbnail?: boolean
  ): Promise<File | null> {
    return new Promise((resolve) => {
      let params = new HttpParams();
      if (isThumbnail) {
        params = params.set('is_thumbnail', isThumbnail?.toString());
      }

      this.http
        .get<Blob>(profile_image_url, {
          observe: 'body',
          responseType: 'blob' as 'json',
          params,
        })
        .subscribe({
          next: (res) => {
            const file = new File([res], 'file.jpg', { type: res.type });
            resolve(file);
          },
          error: () => {
            resolve(null);
          },
        });
    });
  }

  getDMSEntityFromId(
    entityId: string,
    isThumbnail?: boolean,
    externalSystem?: EXTERNAL_SYSTEMS
  ): Promise<File | null> {
    const url = generateURL({
      endpoint: ENDPOINTS.STORAGE_INTERNAL_PREVIEW,
      params: { entityId },
      version: 2,
    });
    // TODO: @sivantha how to handle the token expiration of bucket url
    return new Promise((resolve) => {
      this.http
        .get<CommonResponseDTO<string>>(url, {
          params: {
            ...(isThumbnail ? { is_thumbnail: isThumbnail?.toString() } : {}),
            ...(externalSystem ? { external_system: externalSystem } : {}),
          },
        })
        .subscribe({
          next: (resFirst) => {
            const responseUrl = resFirst.data;

            this.http
              .get<Blob>(responseUrl, {
                observe: 'body',
                responseType: 'blob' as 'json',
              })
              .subscribe({
                next: (res) => {
                  const file = new File([res], 'file.jpg', { type: res.type });
                  resolve(file);
                },
                error: () => {
                  resolve(null);
                },
              });
          },
          error: () => {
            resolve(null);
          },
        });
    });
  }

  async bulkUploadFilesToDMS(
    files: IUploadFileToDMS<File>[]
  ): Promise<CommonResponseDTO<IStorageEntity>[]> {
    return await Promise.all(
      files.map((file) => {
        return firstValueFrom(this.uploadFileToDMS(file));
      })
    );
  }

  private uploadToBucket(
    file: File,
    uploadUrl: string
  ): Observable<CommonResponseDTO<IStorageEntityResponse>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<CommonResponseDTO<IStorageEntityResponse>>(
      uploadUrl,
      formData
    );
  }

  private createEntity(
    info: Omit<IUploadFileToDMS<File>, 'file'>,
    externalSystem?: EXTERNAL_SYSTEMS
  ): Observable<CommonResponseDTO<IStorageEntityResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.STORAGE_INTERNAL_UPLOAD,
      version: 2,
    });

    const body = {
      ...info,
      ...(externalSystem ? { external_system: externalSystem } : {}),
    };

    return this.http.post<CommonResponseDTO<IStorageEntityResponse>>(url, body);
  }

  uploadFileToDMS(
    entity: IUploadFileToDMS<File>,
    externalSystem?: EXTERNAL_SYSTEMS
  ): Observable<CommonResponseDTO<IStorageEntityResponse>> {
    const { file, ...rest } = entity;

    const createEntity = this.createEntity(rest, externalSystem);

    return createEntity.pipe(
      switchMap((res) => this.uploadToBucket(file, res.data.upload_url))
    );
  }

  uploadFileToDMSWithProgress(entity: IUploadFileToDMS<File>) {
    const { file, ...rest } = entity;

    const createEntity = this.createEntity(rest);

    return createEntity.pipe(
      switchMap((res) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.http.post(res.data.upload_url, formData, {
          reportProgress: true,
          observe: 'events',
        });
      })
    );
  }

  printPdfFromDMS(
    entityId: string,
    name = 'download',
    externalSystem?: EXTERNAL_SYSTEMS
  ) {
    const url = generateURL({
      endpoint: ENDPOINTS.STORAGE_INTERNAL_DOWNLOAD,
      params: { entityId },
      version: 2,
    });

    return new Promise((resolve, reject) => {
      this.http
        .get<CommonResponseDTO<string>>(url, {
          params: {
            ...(externalSystem ? { external_system: externalSystem } : {}),
          },
        })
        .subscribe({
          next: (resFirst) => {
            const responseURL = resFirst.data;
            console.log(responseURL, name);

            // TODO:pass the url to print-js and resolve or reject accordingly
          },
          error: () => reject(),
        });
    });
  }

  async downloadEntityFromDMS(
    entityId: string,
    name = 'download',
    externalSystem?: EXTERNAL_SYSTEMS
  ): Promise<void> {
    const url = generateURL({
      endpoint: ENDPOINTS.STORAGE_INTERNAL_DOWNLOAD,
      params: { entityId },
      version: 2,
    });

    return new Promise((resolve, reject) => {
      this.http
        .get<CommonResponseDTO<string>>(url, {
          params: {
            ...(externalSystem ? { external_system: externalSystem } : {}),
          },
        })
        .subscribe({
          next: (resFirst) => {
            const responseURL = resFirst.data;
            this.http
              .get<Blob>(responseURL, {
                observe: 'body',
                responseType: 'blob' as 'json',
              })
              .subscribe({
                next: (res) => {
                  const file = new File([res], 'file.jpg', { type: res.type });
                  const url = URL.createObjectURL(file);
                  const link = document.createElement('a');
                  link.href = url;
                  link.download = name;
                  link.click();
                  resolve();
                },
                error: () => {
                  reject();
                },
              });
          },
          error: () => reject(),
        });
    });
  }

  shareDMSEntitiesInternally(
    entityIds: string[],
    identityIds: string[]
  ): Observable<CommonResponseDTO<void>> {
    const internalShares = entityIds
      .map((entityId) => ({
        entityId,
        identityIds,
      }))
      .flatMap((item) =>
        item.identityIds.map((identity) => ({
          entityId: item.entityId,
          identityId: identity,
        }))
      );

    return from(internalShares).pipe(
      mergeMap((internalShare) => {
        const { entityId, identityId } = internalShare;
        const data = {
          identity_id: identityId,
        };
        const url = generateURL({
          endpoint: ENDPOINTS.STORAGE_INTERNAL_SHARE_INTERNALLY,
          params: { entityId },
        });

        return this.http.post<CommonResponseDTO<void>>(url, data);
      })
    );
  }

  findEntitiesFromDMS(
    entityId: string, // root or objectId of the parent
    queryParams: IGetEntityQuery
  ): Observable<CommonResponseDTO<IStorageEntity[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.STORAGE_INTERNAL_CONTENT,
      params: { entityId: entityId ?? 'root' },
    });

    const params = new HttpParams();

    Object.entries(queryParams).forEach(([key, value]) => {
      params.append(key, value as string);
    });

    return this.http.get<CommonResponseDTO<IStorageEntity[]>>(url, {
      params,
    });
  }
}
