import { forkJoin } from 'rxjs';

import { AuthService } from './modules/auth/services/auth.service';
import { CurrenciesService } from './modules/setup/currencies/services/currencies.service';
import { ExternalSystemsService } from './modules/setup/external-systems/services/external-systems.service';
import { ClientDataService } from './modules/setup/general/services/client-data.service';
import { SystemDataService } from './modules/setup/general/services/system-data.service';
import { IamConfigService } from './modules/setup/iam-config/services/iam-config.service';
import { LanguagesService } from './modules/setup/languages/services/languages.service';
import { NotificationConfigService } from './modules/setup/notifications/services/notification-config.service';
import { PWAService } from './modules/setup/pwa/services/pwa.service';
import { ReleaseService } from './modules/setup/releases/services/release.service';
import { SecurityService } from './modules/setup/security/services/security.service';
import { StorageSetupService } from './modules/setup/storage/services/storage-setup.service';
import { SupportService } from './modules/setup/support/services/support.service';

/**
 * do all the initial configurations here (API fetching, calculations, etc.)
 * do not call APIs with authentications here, because those will fail if the user is not authenticated and,
 * then those functions won't be executed again after authenticating since the app initializer runs only at the startup of the app
 */
export function AppInitializer(
  authService: AuthService,
  releaseService: ReleaseService,
  securityService: SecurityService,
  clientDataService: ClientDataService,
  systemDataService: SystemDataService,
  languageDataService: LanguagesService,
  notificationConfigService: NotificationConfigService,
  pwaDataService: PWAService,
  iamConfigService: IamConfigService,
  storageConfigService: StorageSetupService,
  supportService: SupportService,
  externalSystemsService: ExternalSystemsService,
  currenciesService: CurrenciesService
) {
  return () =>
    new Promise((resolve, reject) => {
      securityService.getSecurityData().subscribe({
        next: () => {
          authService
            .autoLogin()
            .catch(() => {
              authService.redirectToLogin();
            })
            .finally(() => {
              forkJoin({
                iamConfig: iamConfigService.getAllConfigData(),
                storageConfig: storageConfigService.getAllConfigData(),
                client: clientDataService.loadClientData(),
                system: systemDataService.loadSystemData(),
                languages: languageDataService.getAllLanguages(),
                releases: releaseService.getLatestRelease(),
                notifications:
                  notificationConfigService.fetchSystemNotificationConfig(),
                pwa: pwaDataService.loadPWAData(),
                support: supportService.getAllSupportData(),
                externalSystems:
                  externalSystemsService.getExternalSystemsData(),
                currency: currenciesService.getBaseCurrency(),
              }).subscribe({
                next: () => {
                  resolve(true);
                },
                error: () => {
                  // TODO:@sivantha handle this
                  reject(new Error('Failed to initialize the application'));
                },
              });
            });
        },
        error: () => {
          // TODO:@sivantha handle this
          reject(new Error('Failed to get security data'));
        },
      });
    });
}
