import { MODULES } from '../modules';

export enum PERMISSION_TYPES {
  SYSTEM = 'SYSTEM',
  CUSTOM = 'CUSTOM',
  VIRTUAL = 'VIRTUAL',
}

// list only common permissions here
export enum PERMISSION_ACTIONS {
  READ = 'READ',
  WRITE = 'WRITE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
}

export interface PermissionDescription {
  permission: string;
  description: string;
}

// always have the ACTION:MODULE format
// also add a relevant description to above object array in the added format
export enum SPECIAL_PERMISSION_ACTIONS {
  // Login related permissions
  AUTHORIZED_ADMIN_LOGIN = 'AUTHORIZED_ADMIN:LOGIN',

  // Identities-hierarchy related permissions
  IDENTITIES_HIERARCHY_VIEW_SELF = 'VIEW_SELF_HIERARCHY:IDENTITIES',
  IDENTITIES_HIERARCHY_VIEW_SUBORDINATES = 'VIEW_SUBORDINATES_HIERARCHY:IDENTITIES',
  IDENTITIES_HIERARCHY_VIEW_ALL = 'VIEW_ALL_HIERARCHY:IDENTITIES',

  // Identities special permissions
  IDENTITIES_RESEND_CREDENTIALS = 'RESEND_CREDENTIALS:IDENTITIES',

  // Notifications related permissions
  VIEW_ALL_NOTIFICATION = 'VIEW_ALL_NOTIFICATION',

  // Logs related permissions
  VIEW_SYSTEM_LOGS = 'VIEW_SYSTEM:LOGS',
  VIEW_ALL_LOGS = 'VIEW_ALL:LOGS',
  HIERARCHY_LOGS = 'HIERARCHY:LOGS',

  // Storage Admin permissions
  VIEW_SYSTEM_ENTITIES = 'VIEW_SYSTEM_ENTITIES:STORAGE',
  READ_ALL_ENTITIES = 'READ_ALL_ENTITIES:STORAGE',
  WRITE_ALL_ENTITIES = 'WRITE_ALL_ENTITIES:STORAGE',
  EDIT_ALL_ENTITIES = 'EDIT_ALL_ENTITIES:STORAGE',
  DELETE_ALL_ENTITIES = 'DELETE_ALL_ENTITIES:STORAGE',
  RESTORE_ALL_ENTITIES = 'RESTORE_ALL_ENTITIES:STORAGE', // TODO:@sanjeewa use this to restore files from trash
  INTERNAL_SHARE_ALL_ENTITIES = 'INTERNAL_SHARE_ALL_ENTITIES:STORAGE',
  EXTERNAL_SHARE_ALL_ENTITIES = 'EXTERNAL_SHARE_ALL_ENTITIES:STORAGE',
  PUBLIC_SHARE_ALL_ENTITIES = 'PUBLIC_SHARE_ALL_ENTITIES:STORAGE',

  // Storage quota permissions
  READ_STORAGE_QUOTA = 'READ:STORAGE_QUOTA',
  WRITE_STORAGE_QUOTA = 'WRITE:STORAGE_QUOTA',
  READ_USER_QUOTA = 'READ:USER_QUOTA',
  WRITE_USER_QUOTA = 'WRITE:USER_QUOTA',

  // Identities Public API related permissions
  IDENTITIES_INTEGRATION = 'INTEGRATION:IDENTITIES',
  READ_IDENTITIES_API_KEYS = 'READ:IDENTITIES_API_KEYS',
  EDIT_IDENTITIES_API_KEYS = 'EDIT:IDENTITIES_API_KEYS',
  WRITE_IDENTITIES_API_KEYS = 'WRITE:IDENTITIES_API_KEYS',
  DELETE_IDENTITIES_API_KEYS = 'DELETE:IDENTITIES_API_KEYS',
  RESTORE_IDENTITIES_API_KEYS = 'RESTORE:IDENTITIES_API_KEYS',

  // Storage Public API related permissions
  DRIVE_INTEGRATION = 'INTEGRATION:STORAGE_DRIVE',
  READ_STORAGE_API_KEYS = 'READ:STORAGE_API_KEYS',
  EDIT_STORAGE_API_KEYS = 'EDIT:STORAGE_API_KEYS',
  WRITE_STORAGE_API_KEYS = 'WRITE:STORAGE_API_KEYS',
  DELETE_STORAGE_API_KEYS = 'DELETE:STORAGE_API_KEYS',
  RESTORE_STORAGE_API_KEYS = 'RESTORE:STORAGE_API_KEYS',

  // Interfaces related permissions
  SYSTEM_INTEGRATION = 'INTEGRATION:SYSTEM',

  // Profile related permissions
  EDIT_PROFILE = 'EDIT:PROFILE',

  // Storage entities related permissions
  READ_ENTITIES = 'READ:ENTITIES',
  WRITE_ENTITIES = 'WRITE:ENTITIES',
  INTERNAL_SHARE_ENTITIES = 'SHARE_INTERNAL:ENTITIES',
  EXTERNAL_SHARE_ENTITIES = 'SHARE_EXTERNAL:ENTITIES',
  PUBLIC_SHARE_ENTITIES = 'SHARE_PUBLIC:ENTITIES',
  DELETE_ENTITIES = 'DELETE:ENTITIES',
  EDIT_ENTITIES = 'EDIT:ENTITIES',

  // Trash special permissions
  READ_TRASH_INFO = 'READ:TRASH_INFO',

  // Widgets
  READ_ALL_WIDGET_LAYOUTS = 'READ:ALL_WIDGET_LAYOUTS',
  WRITE_ALL_WIDGET_LAYOUTS = 'WRITE:ALL_WIDGET_LAYOUTS',

  // Messages
  ALLOW_REPORTS_TO_IDENTITY = 'ALLOW_REPORTS_TO_IDENTITY:MESSAGES',
  ALLOW_ALL_SUBORDINATE_IDENTITIES = 'ALLOW_ALL_SUBORDINATE_IDENTITIES:MESSAGES',
  ALLOW_IMMEDIATE_SUBORDINATE_IDENTITIES = 'ALLOW_IMMEDIATE_SUBORDINATE_IDENTITIES:MESSAGES',
  ALLOW_REPORTS_TO_ROLE = 'ALLOW_REPORTS_TO_ROLE:MESSAGES',
  ALLOW_ALL_SUBORDINATE_ROLES = 'ALLOW_ALL_SUBORDINATE_ROLES:MESSAGES',
  ALLOW_IMMEDIATE_SUBORDINATE_ROLES = 'ALLOW_IMMEDIATE_SUBORDINATE_ROLES:MESSAGES',
}

export enum PERMISSIONS_TYPES {
  ENTITIES = 'ENTITIES',
  STORAGE_API_KEYS = 'STORAGE_API_KEYS',
  STORAGE = 'STORAGE',
  STORAGE_QUOTA = 'STORAGE_QUOTA',
  USER_QUOTA = 'USER_QUOTA',
  STORAGE_TRASH = 'STORAGE_TRASH',
  TRASH_INFO = 'TRASH_INFO',
  VIEW_ALL_NOTIFICATION = 'VIEW_ALL_NOTIFICATION',
  NOTIFICATIONS = 'NOTIFICATIONS',
  ALL_WIDGET_LAYOUTS = 'ALL_WIDGET_LAYOUTS',
  WIDGET = 'WIDGET',
  INTEGRATIONS = 'INTEGRATIONS',
  SYSTEM = 'SYSTEM',
  IDENTITIES = 'IDENTITIES',
  IDENTITIES_API_KEYS = 'IDENTITIES_API_KEYS',
}

export enum REFERENCE_PERMISSION_ACTIONS {
  // for the category itself
  READ_CATEGORY = 'READ_CATEGORY',
  WRITE_CATEGORY = 'WRITE_CATEGORY',
  EDIT_CATEGORY = 'EDIT_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  RESTORE_CATEGORY = 'RESTORE_CATEGORY',

  // for the items added inside the category
  READ_CATEGORY_ITEMS = 'READ_CATEGORY_ITEMS',
  WRITE_CATEGORY_ITEMS = 'WRITE_CATEGORY_ITEMS',
  APPROVE_CATEGORY_ITEMS = 'APPROVE_CATEGORY_ITEMS',
  EDIT_CATEGORY_ITEMS = 'EDIT_CATEGORY_ITEMS',
  DELETE_CATEGORY_ITEMS = 'DELETE_CATEGORY_ITEMS',
  RESTORE_CATEGORY_ITEMS = 'RESTORE_CATEGORY_ITEMS',
}

export enum INTEGRATION_PERMISSION_ACTIONS {
  //for endpoints
  READ_ENDPOINTS = 'READ:ENDPOINTS',
  WRITE_ENDPOINTS = 'WRITE:ENDPOINTS',
  EDIT_ENDPOINTS = 'EDIT:ENDPOINTS',
  DELETE_ENDPOINTS = 'DELETE:ENDPOINTS',
  RESTORE_ENDPOINTS = 'RESTORE:ENDPOINTS',
  VERIFY_ENDPOINTS = 'VERIFY:ENDPOINTS',

  //for mappings
  READ_MAPPINGS = 'READ:MAPPINGS',
  WRITE_MAPPINGS = 'WRITE:MAPPINGS',
  EDIT_MAPPINGS = 'EDIT:MAPPINGS',
  DELETE_MAPPINGS = 'DELETE:MAPPINGS',
  RESTORE_MAPPINGS = 'RESTORE:MAPPINGS',
  VERIFY_MAPPINGS = 'VERIFY:MAPPINGS',
}

export enum ANALYZING_MODES {
  EVERY = 'every',
  SOME = 'some',
  ALLOW_OWN_IDENTITY = 'allowOwnIdentity',
}

export enum PERMISSION_METADATA_KEYS {
  PERMISSIONS = 'permissions',
  ANALYZING_MODE = 'analyzing_mode',
}

export enum DEFAULT_ROLES {
  IT_ADMIN = 'IT Admin',
  CUSTOMER = 'Customer',
  AGENT = 'Agent',
  ADMIN = 'Admin',
  CALL_CENTER_AGENT = 'Call Center Agent',
  ASSESSOR = 'Assessor',
}

export const DEFAULT_ROLE_CONFIG = {
  [DEFAULT_ROLES.IT_ADMIN]: {
    is_default: false,
    permissions: [],
  },
  [DEFAULT_ROLES.CUSTOMER]: {
    is_default: true,
    permissions: [],
  },
  [DEFAULT_ROLES.AGENT]: {
    is_default: false,
    permissions: [],
  },
  [DEFAULT_ROLES.ADMIN]: {
    is_default: false,
    permissions: [],
  },
  [DEFAULT_ROLES.CALL_CENTER_AGENT]: {
    is_default: false,
    permissions: [],
  },
  [DEFAULT_ROLES.ASSESSOR]: {
    is_default: false,
    permissions: [],
  },
};

interface IDefaultQuickActions {
  icon: string;
  title: string;
  link?: string;
  key?: DEFAULT_QUICK_ACTIONS_KEY;
  permissions: string[];
}

export enum DEFAULT_QUICK_ACTIONS_KEY {
  CALL_US = 'call_us',
}

export const DEFAULT_QUICK_ACTIONS: IDefaultQuickActions[] = [
  //{
  //  icon: 'call',
  //  title: 'dashboard.quick.call-us',
  //  key: DEFAULT_QUICK_ACTIONS_KEY.CALL_US,
  //  permissions: []
  //},
];

const generatePermissionKey = (
  module: MODULES,
  action:
    | PERMISSION_ACTIONS
    | REFERENCE_PERMISSION_ACTIONS
    | INTEGRATION_PERMISSION_ACTIONS,
  subModule?: string
) => {
  const newLocal = `:${subModule}`;
  const permissionString = `${action}:${module}${subModule ? newLocal : ''}`;

  return permissionString
    .replace(/[^a-zA-Z\d\s:]/g, ' ')
    .replace(/\s/g, '_')
    .toUpperCase();
};

export const PERMISSION_DESCRIPTIONS: PermissionDescription[] = [
  {
    permission: generatePermissionKey(MODULES.APPS, PERMISSION_ACTIONS.READ),
    description:
      'Allows the user to view and search information about the connected external apps.',
  },
  {
    permission: generatePermissionKey(MODULES.APPS, PERMISSION_ACTIONS.WRITE),
    description:
      'Allows the user to add and configure new external apps to the system.',
  },
  {
    permission: generatePermissionKey(MODULES.APPS, PERMISSION_ACTIONS.EDIT),
    description:
      'Allows the user to modify and update the settings of existing connected external apps.',
  },
  {
    permission: generatePermissionKey(MODULES.APPS, PERMISSION_ACTIONS.DELETE),
    description:
      'Allows the user to remove or disconnect external apps from the system.',
  },
  {
    permission: generatePermissionKey(MODULES.APPS, PERMISSION_ACTIONS.RESTORE),
    description:
      'Allows the user to recover and restore previously removed or disconnected external apps.',
  },
  {
    permission: generatePermissionKey(MODULES.MODULES, PERMISSION_ACTIONS.READ),
    description: 'Allows the user to view and search modules in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.MODULES,
      PERMISSION_ACTIONS.WRITE
    ),
    description: 'Allows the user to create new modules in the system.',
  },
  {
    permission: generatePermissionKey(MODULES.MODULES, PERMISSION_ACTIONS.EDIT),
    description:
      'Allows the user to modify and update the settings of existing modules.',
  },
  {
    permission: generatePermissionKey(
      MODULES.MODULES,
      PERMISSION_ACTIONS.DELETE
    ),
    description: 'Allows the user to remove or delete modules from the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.MODULES,
      PERMISSION_ACTIONS.RESTORE
    ),
    description: 'Allows the user to recover and restore deleted modules.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_PERMISSIONS,
      PERMISSION_ACTIONS.READ
    ),
    description:
      'Allows the user to view and search the current permissions in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_PERMISSIONS,
      PERMISSION_ACTIONS.WRITE
    ),
    description: 'Allows the user to create new permissions in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_PERMISSIONS,
      PERMISSION_ACTIONS.EDIT
    ),
    description: 'Allows the user to modify and update existing permissions.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_PERMISSIONS,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete permissions in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_PERMISSIONS,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously removed permissions.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_ROLES,
      PERMISSION_ACTIONS.READ
    ),
    description:
      'Allows the user to view and search the current permissions in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_ROLES,
      PERMISSION_ACTIONS.WRITE
    ),
    description: 'Allows the user to create new user roles.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_ROLES,
      PERMISSION_ACTIONS.EDIT
    ),
    description: 'Allows the user to modify and update existing user roles.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_ROLES,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete user roles from the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_ROLES,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously deleted user roles.',
  },
  {
    permission: generatePermissionKey(
      MODULES.INTEGRATIONS,
      INTEGRATION_PERMISSION_ACTIONS.READ_ENDPOINTS
    ),
    description: 'Allows the user to view endpoints in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.INTEGRATIONS,
      INTEGRATION_PERMISSION_ACTIONS.WRITE_ENDPOINTS
    ),
    description: 'Allows the user to create endpoints in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.INTEGRATIONS,
      INTEGRATION_PERMISSION_ACTIONS.EDIT_ENDPOINTS
    ),
    description:
      'Allows the user to update an existing endpoint in the system.',
  },
  {
    permission: INTEGRATION_PERMISSION_ACTIONS.DELETE_ENDPOINTS,
    description: 'Allows the user to delete an endpoint in the system.',
  },
  {
    permission: INTEGRATION_PERMISSION_ACTIONS.RESTORE_ENDPOINTS,
    description: 'Allows the user to restore a deleted endpoint in the system.',
  },
  {
    permission: INTEGRATION_PERMISSION_ACTIONS.VERIFY_ENDPOINTS,
    description: 'Allows the user to confirm the validity of an endpoint.',
  },
  {
    permission: INTEGRATION_PERMISSION_ACTIONS.READ_MAPPINGS,
    description: 'Allows the user to view mappings in the system.',
  },
  {
    permission: INTEGRATION_PERMISSION_ACTIONS.WRITE_MAPPINGS,
    description: 'Allows the user to create mappings in the system.',
  },
  {
    permission: INTEGRATION_PERMISSION_ACTIONS.EDIT_MAPPINGS,
    description: 'Allows the user to update an existing mapping in the system.',
  },
  {
    permission: INTEGRATION_PERMISSION_ACTIONS.DELETE_MAPPINGS,
    description: 'Allows the user to delete a mapping in the system.',
  },
  {
    permission: INTEGRATION_PERMISSION_ACTIONS.RESTORE_MAPPINGS,
    description: 'Allows the user to restore a deleted mapping in the system.',
  },
  {
    permission: INTEGRATION_PERMISSION_ACTIONS.VERIFY_MAPPINGS,
    description: 'Allows the user to confirm the validity of a mapping.',
  },
  {
    permission: generatePermissionKey(
      MODULES.IDENTITIES,
      PERMISSION_ACTIONS.READ
    ),
    description:
      'Allows the user to view and search the identities within the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.IDENTITIES,
      PERMISSION_ACTIONS.WRITE
    ),
    description: 'Allows the user to create new identities.',
  },
  {
    permission: generatePermissionKey(
      MODULES.IDENTITIES,
      PERMISSION_ACTIONS.EDIT
    ),
    description: 'Allows the user to modify and update existing identities.',
  },
  {
    permission: generatePermissionKey(
      MODULES.IDENTITIES,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete identities from the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.IDENTITIES,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously removed identities.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.READ_IDENTITIES_API_KEYS,
    description:
      'Allows the user to view all the identities using the public API endpoints.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.WRITE_IDENTITIES_API_KEYS,
    description:
      'Allows the user to create new identities using the public API endpoints.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.EDIT_IDENTITIES_API_KEYS,
    description:
      'Allows the user to modify and update existing identities using public API endpoints.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.DELETE_IDENTITIES_API_KEYS,
    description:
      'Allows the user to remove or delete identities from the system using public API endpoints.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.RESTORE_IDENTITIES_API_KEYS,
    description:
      'Allows the user to recover and restore previously removed identities using the public API endpoints.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.IDENTITIES_HIERARCHY_VIEW_SELF,
    description: 'Allows the user to view their hierarchy in the system.',
  },
  {
    permission:
      SPECIAL_PERMISSION_ACTIONS.IDENTITIES_HIERARCHY_VIEW_SUBORDINATES,
    description:
      'Allows the user to view their hierarchy with their subordinates in the system.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.IDENTITIES_HIERARCHY_VIEW_ALL,
    description: 'Allows the user to view all the hierarchies in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.CONNECTIONS,
      PERMISSION_ACTIONS.READ
    ),
    description: 'Allows the user to view and search the current connections.',
  },
  {
    permission: generatePermissionKey(
      MODULES.CONNECTIONS,
      PERMISSION_ACTIONS.WRITE
    ),
    description:
      'Allows the user to create new connections for identities to the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.CONNECTIONS,
      PERMISSION_ACTIONS.EDIT
    ),
    description: 'Allows the user to modify and update existing connections.',
  },
  {
    permission: generatePermissionKey(
      MODULES.CONNECTIONS,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete connections for identities from the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.CONNECTIONS,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously removed connections.',
  },
  {
    permission: generatePermissionKey(
      MODULES.MESSAGES,
      PERMISSION_ACTIONS.READ
    ),
    description:
      'Allows the user to view and search the messages exchanged with identities, roles, and groups within the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.MESSAGES,
      PERMISSION_ACTIONS.WRITE
    ),
    description:
      'Allows the user to create new messages and send them to identities, roles, and groups within the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.MESSAGES,
      PERMISSION_ACTIONS.EDIT
    ),
    description: 'Allows the user to modify and update existing messages.',
  },
  {
    permission: generatePermissionKey(
      MODULES.MESSAGES,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete messages from the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.MESSAGES,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously deleted messages.',
  },
  {
    permission: generatePermissionKey(MODULES.LOGS, PERMISSION_ACTIONS.READ),
    description: 'Allows the user to view and search the current logs.',
  },
  {
    permission: generatePermissionKey(MODULES.LOGS, PERMISSION_ACTIONS.WRITE),
    description: 'Allows the user to create new logs in the system.',
  },
  {
    permission: generatePermissionKey(MODULES.LOGS, PERMISSION_ACTIONS.EDIT),
    description: 'Allows the user to modify and update existing logs.',
  },
  {
    permission: generatePermissionKey(MODULES.LOGS, PERMISSION_ACTIONS.DELETE),
    description: 'Allows the user to remove or delete logs from the system.',
  },
  {
    permission: generatePermissionKey(MODULES.LOGS, PERMISSION_ACTIONS.RESTORE),
    description:
      'Allows the user to recover and restore previously deleted logs.',
  },
  {
    permission: generatePermissionKey(
      MODULES.REFERENCES,
      PERMISSION_ACTIONS.READ
    ),
    description:
      'Allows the user to view and search the reference data in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.REFERENCES,
      PERMISSION_ACTIONS.WRITE
    ),
    description: 'Allows the user to create new reference data.',
  },
  {
    permission: generatePermissionKey(
      MODULES.REFERENCES,
      PERMISSION_ACTIONS.EDIT
    ),
    description:
      'Allows the user to modify and update existing reference data.',
  },
  {
    permission: generatePermissionKey(
      MODULES.REFERENCES,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete reference data from the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.REFERENCES,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously deleted reference data.',
  },
  {
    permission: generatePermissionKey(MODULES.NOTICES, PERMISSION_ACTIONS.READ),
    description: 'Allows the user to view and search notices in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.NOTICES,
      PERMISSION_ACTIONS.WRITE
    ),
    description: 'Allows the user to create new notices.',
  },
  {
    permission: generatePermissionKey(MODULES.NOTICES, PERMISSION_ACTIONS.EDIT),
    description: 'Allows the user to modify and update existing notices.',
  },
  {
    permission: generatePermissionKey(
      MODULES.NOTICES,
      PERMISSION_ACTIONS.DELETE
    ),
    description: 'Allows the user to remove or delete notices from the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.NOTICES,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously deleted notices.',
  },
  {
    permission: generatePermissionKey(
      MODULES.KNOWLEDGEBASE,
      PERMISSION_ACTIONS.READ
    ),
    description: 'Allows the user to view and search the knowledge base.',
  },
  {
    permission: generatePermissionKey(
      MODULES.KNOWLEDGEBASE,
      PERMISSION_ACTIONS.WRITE
    ),
    description:
      'Allows the user to create new articles in the knowledge base.',
  },
  {
    permission: generatePermissionKey(
      MODULES.KNOWLEDGEBASE,
      PERMISSION_ACTIONS.EDIT
    ),
    description:
      'Allows the user to modify and update existing articles in the knowledge base.',
  },
  {
    permission: generatePermissionKey(
      MODULES.KNOWLEDGEBASE,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete articles from the knowledge base.',
  },
  {
    permission: generatePermissionKey(
      MODULES.KNOWLEDGEBASE,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously deleted articles.',
  },
  {
    permission: generatePermissionKey(
      MODULES.NOTIFICATIONS,
      PERMISSION_ACTIONS.READ
    ),
    description:
      'Allows the user to view and search the current notifications.',
  },
  {
    permission: generatePermissionKey(
      MODULES.NOTIFICATIONS,
      PERMISSION_ACTIONS.WRITE
    ),
    description: 'Allows the user to create new notifications.',
  },
  {
    permission: generatePermissionKey(
      MODULES.NOTIFICATIONS,
      PERMISSION_ACTIONS.EDIT
    ),
    description: 'Allows the user to modify and update existing notifications.',
  },
  {
    permission: generatePermissionKey(
      MODULES.NOTIFICATIONS,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete notifications from the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.NOTIFICATIONS,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously deleted notifications.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_DRIVE,
      PERMISSION_ACTIONS.READ
    ),
    description:
      'Allows the user to view and search the uploaded files or folders in the storage drive.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_DRIVE,
      PERMISSION_ACTIONS.WRITE
    ),
    description:
      'Allows the user to create and upload new folders and files in the storage drive.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_DRIVE,
      PERMISSION_ACTIONS.EDIT
    ),
    description:
      'Allows the user to modify and update the files or folders in the storage drive.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_DRIVE,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete files or folders from the storage drive.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_DRIVE,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously removed or deleted files or folders from the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.READ_ENTITIES,
    description:
      'Allows the user to view folders and files in the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.WRITE_ENTITIES,
    description:
      'Allows the user to create and upload new folders and files in the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.EDIT_ENTITIES,
    description:
      'Allows the user to modify and update the files or folders in the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.DELETE_ENTITIES,
    description:
      'Allows the user to remove or delete files or folders from the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.RESTORE_ALL_ENTITIES,
    description:
      'Allows the user to recover and restore previously removed or deleted files or folders from the storage drive.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_DRIVE,
      PERMISSION_ACTIONS.READ
    ),
    description:
      'Allows the user to view and search the uploaded files or folders in the storage drive.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_DRIVE,
      PERMISSION_ACTIONS.WRITE
    ),
    description:
      'Allows the user to create and upload new folders and files in the storage drive.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_DRIVE,
      PERMISSION_ACTIONS.EDIT
    ),
    description:
      'Allows the user to modify and update the files or folders in the storage drive.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_DRIVE,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete files or folders from the storage drive.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_DRIVE,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously removed or deleted files or folders from the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.READ_ALL_WIDGET_LAYOUTS,
    description: 'Allows user to view all widget layouts.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.WRITE_ALL_WIDGET_LAYOUTS,
    description: 'Allows user to create widget layouts.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.READ_TRASH_INFO,
    description: 'Allows user to view items in the trash.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.EDIT_PROFILE,
    description: 'Allows user to edit profile their information.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.IDENTITIES_RESEND_CREDENTIALS,
    description:
      'Allows user to resend verify credentials prompt for unverified identities',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.VIEW_ALL_NOTIFICATION,
    description: 'Allows user to view all notifications',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.VIEW_ALL_LOGS,
    description:
      'Allows the user to view and search all logs within the system.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.VIEW_SYSTEM_LOGS,
    description:
      'Allows the user to view and search system-related logs within the system.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.HIERARCHY_LOGS,
    description:
      'Allows the user to view and search hierarchy logs within the system.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.VIEW_SYSTEM_ENTITIES,
    description:
      'Allows the user to view system files and folders in the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.READ_ALL_ENTITIES,
    description:
      'Allows the user to view and search all the uploaded files or folders in the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.WRITE_ALL_ENTITIES,
    description:
      'Allows the user to add or create new files or folders in the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.EDIT_ALL_ENTITIES,
    description:
      'Allows the user to modify and update all the uploaded files or folders in the storage drive',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.DELETE_ALL_ENTITIES,
    description:
      'Allows the user to remove or delete all the uploaded files or folders from the storage drive',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.INTERNAL_SHARE_ALL_ENTITIES,
    description:
      'Allows the user to internally share the uploaded files or folders in the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.EXTERNAL_SHARE_ALL_ENTITIES,
    description:
      'Allows the user to externally share the uploaded files or folders in the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.PUBLIC_SHARE_ALL_ENTITIES,
    description:
      'Allows the user to publicly share the uploaded files or folders in the storage drive.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.READ_USER_QUOTA,
    description: 'Allows the user to read user storage quota configurations.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.WRITE_USER_QUOTA,
    description:
      'Allows the user to change and update user storage quota configurations',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.READ_STORAGE_API_KEYS,
    description:
      'Allows the user to view and search the uploaded files or folders in the storage drive using the public API endpoints.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.EDIT_STORAGE_API_KEYS,
    description:
      'Allows the user to modify and update uploaded files or folders in the storage drive using the public API endpoints.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.WRITE_STORAGE_API_KEYS,
    description:
      'Allows the user to create and upload new folders and files in the storage drive using public API endpoints.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.DELETE_STORAGE_API_KEYS,
    description:
      'Allows the user to remove or delete uploaded files or folders in the storage drive using the public API endpoints.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.RESTORE_STORAGE_API_KEYS,
    description:
      'Allows the user to recover and restore uploaded files or folders in the storage drive using the public API endpoints.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.ALLOW_REPORTS_TO_IDENTITY,
    description:
      'Allows the user to message reports to identity in the system.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.ALLOW_ALL_SUBORDINATE_IDENTITIES,
    description:
      'Allows the user to message all subordinate identities in the system.',
  },
  {
    permission:
      SPECIAL_PERMISSION_ACTIONS.ALLOW_IMMEDIATE_SUBORDINATE_IDENTITIES,
    description:
      'Allows the user to message immediate subordinate identities in the system.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.ALLOW_REPORTS_TO_ROLE,
    description: 'Allows the user to message reports to role in the system.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.ALLOW_ALL_SUBORDINATE_ROLES,
    description:
      'Allows the user to message all subordinate roles in the system.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.ALLOW_IMMEDIATE_SUBORDINATE_ROLES,
    description:
      'Allows the user to message immediate subordinate roles in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_PRIVILEGES,
      PERMISSION_ACTIONS.READ
    ),
    description:
      'Allows the user to view and search the current privileges in the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_PRIVILEGES,
      PERMISSION_ACTIONS.WRITE
    ),
    description: 'Allows the user to create new privileges.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_PRIVILEGES,
      PERMISSION_ACTIONS.EDIT
    ),
    description: 'Allows the user to create new privileges.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_PRIVILEGES,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete privileges from the system.',
  },
  {
    permission: generatePermissionKey(
      MODULES.AUTHORIZATION_PRIVILEGES,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore previously deleted privileges.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_RECYCLE_BIN,
      PERMISSION_ACTIONS.READ
    ),
    description:
      'Allows the user to view and search the files or folders in the storage trash.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_RECYCLE_BIN,
      PERMISSION_ACTIONS.WRITE
    ),
    description:
      'Allows the user to add folders and files to the storage trash.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_RECYCLE_BIN,
      PERMISSION_ACTIONS.EDIT
    ),
    description:
      'Allows the user to modify and update files or folders in the storage trash.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_RECYCLE_BIN,
      PERMISSION_ACTIONS.DELETE
    ),
    description:
      'Allows the user to remove or delete files or folders in the storage trash.',
  },
  {
    permission: generatePermissionKey(
      MODULES.STORAGE_RECYCLE_BIN,
      PERMISSION_ACTIONS.RESTORE
    ),
    description:
      'Allows the user to recover and restore files or folders in the storage drive.',
  },
  {
    permission: REFERENCE_PERMISSION_ACTIONS.READ_CATEGORY,
    description: 'Allows the user to view reference categories in the system.',
  },
  {
    permission: REFERENCE_PERMISSION_ACTIONS.WRITE_CATEGORY,
    description:
      'Allows the user to create new reference categories for the system. ',
  },
  {
    permission: REFERENCE_PERMISSION_ACTIONS.EDIT_CATEGORY,
    description:
      'Allows the user to modify and update existing reference categories.',
  },
  {
    permission: REFERENCE_PERMISSION_ACTIONS.DELETE_CATEGORY,
    description:
      'Allows the user to remove or delete reference categories from the system.',
  },
  {
    permission: REFERENCE_PERMISSION_ACTIONS.RESTORE_CATEGORY,
    description:
      'Allows the user to recover and restore previously deleted reference categories.',
  },
  {
    permission: REFERENCE_PERMISSION_ACTIONS.READ_CATEGORY_ITEMS,
    description:
      'Allows the user to view reference category values in the system.',
  },
  {
    permission: REFERENCE_PERMISSION_ACTIONS.WRITE_CATEGORY_ITEMS,
    description:
      'Allows the user to create new reference category values for the system.',
  },
  {
    permission: REFERENCE_PERMISSION_ACTIONS.EDIT_CATEGORY_ITEMS,
    description:
      'Allows the user to modify and update existing reference category values.',
  },
  {
    permission: REFERENCE_PERMISSION_ACTIONS.DELETE_CATEGORY_ITEMS,
    description:
      'Allows the user to remove or delete reference category values from the system.',
  },
  {
    permission: REFERENCE_PERMISSION_ACTIONS.RESTORE_CATEGORY_ITEMS,
    description:
      'Allows the user to recover and restore previously deleted reference category values.',
  },
  {
    permission: REFERENCE_PERMISSION_ACTIONS.APPROVE_CATEGORY_ITEMS,
    description:
      'Allows the user to approve added reference category values in the system.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.READ_STORAGE_QUOTA,
    description:
      'Allows the user to read storage quota configurations for the drives in the system.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.WRITE_STORAGE_QUOTA,
    description:
      'Allows the user to add new storage quota configurations for the drives in the system.',
  },
  {
    permission: SPECIAL_PERMISSION_ACTIONS.AUTHORIZED_ADMIN_LOGIN,
    description: 'Allows admins with the permission to login to the system',
  },
];
