// NOTE 1: If we want to initiate some system configs for these external system,
// we can do it with the .env file.

// For the URL_SHORTENER, both DOMAIN and API_KEY are already being added there
// They are being labeled as URL_SHORTENER_DOMAIN and URL_SHORTENER_API_KEY

// Those above 2 keys are being automatically detected by the ExternalSystemsConfigDatabaseService
// and then write them to the DB.

// By maintaining the same pattern, can configure any variables in .env

/**
 * eg:
 *
 * if a new enum value added to EXTERNAL_SYSTEMS as ABC_SYSTEM = 'ABC_SYSTEM' let's say,
 *
 * by adding
 *    - ABC_SYSTEM_DOMAIN
 *    - ABC_SYSTEM_API_KEY
 *
 * to the .env file, we can initiate those 2 external systems with given default values as well
 *
 */

// ------------------------------------------------------------------------------------------------ //

// NOTE 2: In translations JSON files, add something similar to setup.external-systems.URL_SHORTENER
// then the UI translations also may work without any issue

/**
 * eg:
 *
 * if a new enum value added to EXTERNAL_SYSTEMS as ABC_SYSTEM = 'ABC_SYSTEM' let's say,
 *
 * by adding setup.external-systems.ABC_SYSTEM translations to the translation JSON files
 *
 * then the UI translations also may work without any issue
 *
 */

// Extend this constant appropriately for the systems that we want
export enum EXTERNAL_SYSTEMS {
  URL_SHORTENER = 'URL_SHORTENER',
  DEV = 'DEV',
}
