export enum RESPONSE_MESSAGES {
  BLOCKED_USER = 'ERR_BLOCKED_USER',
  AUTH_REQUEST_NOT_FOUND = 'ERR_AUTH_REQUEST_NOT_FOUND',
  NO_IDENTITY_FOR_ACCESS_CREDENTIALS = 'ERR_NO_IDENTITY_FOR_ACCESS_CREDENTIALS',
  GRANT_TYPE_NOT_SUPPORTED_FOR_THE_USER = 'ERR_GRANT_TYPE_NOT_SUPPORTED_FOR_THE_USER',
  THIRD_PARTY_AUTH_NOT_ENABLED = 'ERR_THIRD_PARTY_AUTH_NOT_ENABLED',
  USER_NOT_CONNECTED = 'ERR_USER_NOT_CONNECTED',
  DATA_NOT_FOUND = 'ERR_DATA_NOT_FOUND',
  DUPLICATE_DATA = 'ERR_DUPLICATE_DATA',
  DB_FAILURE = 'ERR_DB_FAILURE',
  DEVOPS_FAILURE = 'ERR_DEVOPS_FAILURE',
  FILE_ACCESS_ISSUE = 'ERR_FILE_ACCESS_ISSUE',
  INVALID_API_KEY = 'ERR_INVALID_API_KEY',
  INVALID_CREDENTIALS = 'ERR_INVALID_CREDENTIALS',
  INVALID_CONFIGURATION = 'ERR_INVALID_CONFIGURATION',
  INVALID_TOKEN = 'ERR_INVALID_TOKEN',
  INVALID_DOCUMENT = 'ERR_INVALID_DOCUMENT',
  INVALID_FILE = 'ERR_INVALID_FILE',
  INVALID_FOLDER = 'ERR_INVALID_FOLDER',
  INVALID_ID = 'ERR_INVALID_ID',
  INVALID_PALLET_INFO = 'ERR_INVALID_PALLET_INFO',
  INVALID_FILE_PATH_CONFIG = 'ERR_INVALID_FILE_PATH_CONFIG',
  INVALID_FIELDS = 'ERR_INVALID_FIELDS',
  INVALID_REQUEST_BODY = 'ERR_INVALID_REQUEST_BODY',
  INVALID_PASSWORD = 'ERR_INVALID_PASSWORD',
  PASSWORD_AZURE_ERROR = 'CANNOT_SET_PASSWORD_FOR_AZURE',
  INVALID_USERNAME = 'ERR_INVALID_USERNAME',
  INVALID_ENCRYPTED_CIPHER = 'INVALID_ENCRYPTED_CIPHER',
  NOT_FOUND = 'ERR_NOT_FOUND',
  NO_QUOTA_FOUND = 'ERR_NO_STORAGE_QUOTA_FOUND',
  STORAGE_QUOTA_EXCEEDED = 'STORAGE_QUOTA_EXCEEDED',
  USER_QUOTA_EXCEEDED = 'USER_QUOTA_EXCEEDED',
  USER_NOT_FOUND = 'ERR_USER_NOT_FOUND',
  FILE_NOT_FOUND = 'ERR_FILE_NOT_FOUND',
  ROLE_NOT_FOUND = 'ERR_ROLE_NOT_FOUND',
  TOKEN_ALREADY_USED = 'ERR_TOKEN_ALREADY_USED',
  TOKEN_EXPIRED = 'ERR_TOKEN_EXPIRED',
  CREDENTIALS_NOT_FOUND = 'ERR_CREDENTIALS_NOT_FOUND',
  PASSWORD_RESET_NOT_SUPPORTED = 'ERR_PASSWORD_RESET_NOT_SUPPORTED',
  DECRYPTION_FAILED = 'ERR_DECRYPTION_FAILED',
  SYSTEM_PERMISSION = 'ERR_CANNOT_DELETE_SYSTEM_PERMISSIONS',
  PERMISSIONS_NOT_UNIQUE = 'ERR_PERMISSIONS_NOT_UNIQUE',
  PRIVILEGE_NOT_UNIQUE = 'ERR_PRIVILEGE_NOT_UNIQUE',
  USER_INFORMATION_INSUFFICIENT = 'ERR_USER_INFORMATION_INSUFFICIENT',
  IDENTITY_NOT_ACTIVATED = 'ERR_IDENTITY_NOT_ACTIVATED',
  ENTITY_NAME_NOT_UNIQUE = 'ERR_ENTITY_NAME_NOT_UNIQUE',
  REFERENCE_NOT_UNIQUE = 'ERR_REFERENCE_NOT_UNIQUE',
  APP_NOT_CONNECTED = 'ERR_APP_NOT_CONNECTED',
  STORAGE_NOT_CONNECTED = 'ERR_STORAGE_NOT_CONNECTED',
  USERNAME_TAKEN = 'ERR_USERNAME_TAKEN',
  PASSWORD_ALREADY_USED_BEFORE = 'ERR_PASSWORD_ALREADY_USED_BEFORE',
  UNAUTHORIZED_ACTION = 'ERR_UNAUTHORIZED_ACTION',
  CANNOT_DELETE_INTERNAL_IAM = 'ERR_CANNOT_DELETE_INTERNAL_IAM',
  CANNOT_DELETE_INTERNAL_STORAGE = 'ERR_CANNOT_DELETE_INTERNAL_STORAGE',
  BODY_TOO_LARGE = 'ERR_REQUEST_ENTITY_TOO_LARGE',
  EMAIL_CONFIG_ERROR = 'ERR_EMAIL_CONFIG',
  SMS_CONFIG_ERROR = 'ERR_SMS_CONFIG',
  NOT_ALLOWED_ACTION = 'ERR_NOT_ALLOWED_ACTION',
  INVALID_VALUE_GIVEN = 'ERR_INVALID_VALUE_GIVEN',
  NOT_ALLOWED_PARENT_ID_AND_PATH = 'ERR_NOT_ALLOWED_BOTH_PARENT_ID_AND_PATH_AT_ONE',
  IDENTITY_NOT_ALLOWED_PERMISSION = 'ERR_IDENTITY_NOT_ALLOWED_PERMISSION',
  NOT_ALLOWED_ONLY_SPACES = 'ERR_NOT_ALLOWED_ONLY_SPACES',
  OTP_EXPIRED = 'ERR_OTP_EXPIRED',
  OTP_USED = 'ERR_USED_OTP',
  DEPENDENCY_ISSUE = 'ERR_DEPENDENCY_ISSUE',
  CONFIG_ERROR = 'CONFIG_ERROR',
  ONE_SIGNAL_ERROR = 'ONE_SIGNAL_ERROR',
  FIND_ENTITIES_ERROR = 'ERR_FIND_ENTITIES',
  GEN_FILE_SAVE_ERROR = 'ERR_GENERATED_FILE_SAVE',
  AZURE_TOKEN_FAIL = 'ERR_AZURE_TOKEN_ENDPOINT',
  PROXY_FAILURE = 'ERR_PROXY_FAILURE',
}

export enum ERROR_CODES {
  PASSWORD_USED_BEFORE = 100001,
  SAME_PASSWORD = 100002,
  CONFLICT_EMAIL = 100003,
  CONFLICT_PHONE_NUMBER = 100004,
  CONFLICT_UNIQUE_FILED = 100005,
}

export enum RESPONSE_STATUSES {
  OFFLINE = 0,
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,
  SUCCESS = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  AMBIGUOUS = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,

  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  TOKEN_EXPIRED_ERROR = 419,
  MISDIRECTED = 421,
  UNPROCESSABLE_ENTITY = 422,
  FAILED_DEPENDENCY = 424,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,

  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
}
